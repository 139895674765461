.first-purchase-flag__text {
    font-weight: $font-weight-bold;
}

.first-purchase-offer__button {
    position: relative;
    border-radius: $radius--default;
    box-shadow: 0 2px 3px 0 black;
}

.first-purchase-offer__item {
    @include item-component((width: 45%));
}

@media only screen and (max-width: $break-point--480) {
    .first-purchase-offer__item {
        @include item-component((width: 60%));
    }

    .first-purchase-offer__item {
        font-size: 1.4rem;
    }
}
