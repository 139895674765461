.room__wrapper {
    &.custom {
        background-color: #951a36;
        background-image: url(imageUrl('lobby/room-bg-custom-belote.svg'));
        .nav--room--box__image--icon {
            background-image: url(imageUrl('lobby/vip-room-crown.png'));
        }
    }
    &.tournament {
        background-color: #ef7d00;
        background-image: url(imageUrl('lobby/room-bg-tournaments-belote.svg'));
        .nav--room--box__image--icon {
            background-image: url(imageUrl('lobby/tournament-room-cup.png'));
        }
    }
}