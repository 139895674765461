.room__wrapper {
    &.training {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #2d4c91;
        background-image: url(imageUrl('lobby/room-bg-training-belote.svg'));
        .room--box__image--icon {
            background-image: url(imageUrl('lobby/training-room-bot.png'));
        }
    }
}

.room-tile__permission-cover {
    &.not-permitted {
        background: #00000050;
    }
}