.pl__container--professionals__bclass {
    &:nth-of-type(1) {
        width: 12rem !important;
        height: 13rem !important;
    }

    &:nth-of-type(2) {
        width: 11rem !important;
        height: 12rem !important;
    }

    &:nth-of-type(3) {
        width: 11rem !important;
        height: 12rem !important;
    }

    @media screen and (max-width: $break-point--1366) {
        &:nth-of-type(1) {
            width: 11rem !important;
            height: 12rem !important;
        }
    
        &:nth-of-type(2) {
            width: 10rem !important;
            height: 11rem !important;
        }
    
        &:nth-of-type(3) {
            width: 10rem !important;
            height: 11rem !important;
        }
    }

    @media screen and (max-width: $break-point--768) {
        &:nth-of-type(1) {
            width: 9rem !important;
            height: 10rem !important;
        }
    
        &:nth-of-type(2) {
            width: 8rem !important;
            height: 9rem !important;
        }
    
        &:nth-of-type(3) {
            width: 8rem !important;
            height: 9rem !important;
        }
    }


    @media screen and (max-width: $break-point--440) {
        &:nth-of-type(1) {
            width: 8rem !important;
            height: 9rem !important;
        }
    
        &:nth-of-type(2) {
            width: 7rem !important;
            height: 8rem !important;
        }
    
        &:nth-of-type(3) {
            width: 7rem !important;
            height: 8rem !important;
        }
    }
}