.game-tile__wrapper {
    &.game-id__belote {
        background-image: url(imageUrl("games/belote.jpg"));
    }
    &.game-id__coinche {
        background-image: url(imageUrl("games/coinche.jpg"));
    }
    &.game-id__contree {
        background-image: url(imageUrl("games/contree.jpg"));
    }
}